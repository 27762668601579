/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import React, { useRef } from 'react'
import { useGLTF } from '@react-three/drei'

export default function Model(props) {
  const group = useRef()
  const { nodes, materials } = useGLTF('models/dcConnector.glb')
  return (
    <group ref={group} {...props} dispose={null}>
      <mesh
        material={materials['Metal vieux']}
        geometry={nodes.Cylinder078.geometry}
        position={[-4.30, 5.55, -1.19]}
        rotation={[Math.PI, 0, -Math.PI / 2]}
        scale={[1.06, 1.06, 1.06]}
      >
        <meshStandardMaterial roughness={0.7} color="#111" />
      </mesh>
    </group>
  )
}

useGLTF.preload('models/dcConnector.glb')

/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import React, { useRef } from 'react'
import { useGLTF, useTexture } from "@react-three/drei";

export default function Model(props) {
  const group = useRef()
  const { nodes, materials } = useGLTF('models/front.glb')
  // const texture_1 = useTexture('logo192.png');

  return (
    <group ref={group} {...props} dispose={null}>
      <mesh
        castShadow
        material-color="green"
        geometry={nodes.Coque_1.geometry}
        position={[0.34, 0.18, 1]}
        rotation={[Math.PI / 2, 0, 0]}
        scale={[1.06, 1.06, 1.06]}
      >
        <meshStandardMaterial
          envMapIntensity={0.1}
          opacity={1}
          attach="material"
          color={props.color}
          transparent
          metalness={0}
          roughness={1}
        />
      </mesh>
    </group>
  );
}

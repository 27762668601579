/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import React, { useRef } from 'react'
import { useGLTF } from '@react-three/drei'

export default function Model(props) {
  const group = useRef()
  const { nodes, materials } = useGLTF('models/buttons.glb')
  return (
    <group ref={group} {...props} dispose={null}>
      <mesh
        material={materials['Plastique rouge']}
        geometry={nodes.Object161.geometry}
        position={[2.24,-2.85, 0.55]}
        rotation={[Math.PI / 2, 0, 0]}
        scale={[1.06, 1.06, 1.06]}>
        <mesh
          material={materials['Plastique rouge']}
          geometry={nodes.Cylinder002.geometry}
          position={[-0.01, 0.03, 0]}
        >
          <meshStandardMaterial envMapIntensity={0.2} roughness={0.8} color={props.color} />
        </mesh>
        <meshStandardMaterial envMapIntensity={0.2} roughness={0.8} color={props.color} />
      </mesh>
    </group>
  )
}

useGLTF.preload('models/buttons.glb')

/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import React, { useRef } from 'react'
import { useGLTF, Plane, useTexture } from '@react-three/drei'

export default function Model(props) {
  const texture_1 = useTexture("models/cartridge.png");
  const group = useRef()
  const { nodes, materials } = useGLTF('models/cartridge.glb')
  return (
    <group ref={group} {...props} dispose={null}>
      <group rotation={[Math.PI / 2, 0, 0]} scale={[1.6, 1.6, 1.6]}>
        <mesh
          material={materials.blinn1}
          geometry={nodes.polySurface152.geometry}
          position={[0.28, 0.2, -0.21]}
        >
          <meshStandardMaterial
            envMapIntensity={0.1}
            color="#999"
          ></meshStandardMaterial>
        </mesh>
      </group>
      <mesh
        position={[0.43, 3.9, -1.9]}
        rotation={[0, Math.PI / 1, 0]}
        scale={[0.95, 0.95, 0.95]}
      >
        <Plane args={[5, 4.5]}>
          <meshStandardMaterial
            attach="material"
            color="#999"
            metalness={0.3}
            roughness={0.1}
            transparent
            map={texture_1}
          ></meshStandardMaterial>
        </Plane>
      </mesh>
    </group>
  );
}

useGLTF.preload('models/cartridge.glb')

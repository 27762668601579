/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import React, { useRef } from 'react'
import { useGLTF } from '@react-three/drei'

export default function Model(props) {
  const group = useRef()
  const { nodes, materials } = useGLTF('models/pills.glb')
  return (
    <group ref={group} {...props} dispose={null}>
      <mesh
        material={materials["Gomme grise"]}
        geometry={nodes.start_select.geometry}
        position={[0.62, -4.95, 0.58]}
        rotation={[Math.PI / 2, 0, 0]}
        scale={[1.01, 1.01, 1.01]}
      >
        <meshStandardMaterial envMapIntensity={0.2} color={props.color} />
      </mesh>
    </group>
  );
}

useGLTF.preload('models/pills.glb')

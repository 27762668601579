/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import React, { useRef } from 'react'
import { useGLTF } from '@react-three/drei'

export default function Model(props) {
  const group = useRef()
  const { nodes, materials } = useGLTF('models/screws.glb')
  return (
    <group ref={group} {...props} dispose={null}>
      <group position={[0.36,0.2, -1.30]} rotation={[Math.PI / 2, 0, 0]} scale={[1.06, 1.01, 1.06]}>
        <mesh
          material={materials['Metal réflechissant']}
          geometry={nodes.Vis018.geometry}
          position={[3.92, 0.39, -5.86]}
          rotation={[Math.PI, 0, 0]}
        />
        <mesh
          material={materials['Metal réflechissant']}
          geometry={nodes.Vis019.geometry}
          position={[-3.77, 0.39, -5.86]}
          rotation={[Math.PI, 0, 0]}
        />
        <mesh
          material={materials['Metal réflechissant']}
          geometry={nodes.Vis020.geometry}
          position={[-3.77, 0.39, -0.74]}
          rotation={[Math.PI, 0, 0]}
        />
        <mesh
          material={materials['Metal réflechissant']}
          geometry={nodes.Vis021.geometry}
          position={[3.92, 0.39, -0.74]}
          rotation={[Math.PI, 0, 0]}
        />
      </group>
    </group>
  )
}

useGLTF.preload('models/screws.glb')

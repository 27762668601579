/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import React, { useRef } from 'react'
import { useGLTF } from '@react-three/drei'

export default function Model(props) {
  const group = useRef()
  const { nodes, materials } = useGLTF('models/scrollRight.glb')
  return (
    <group ref={group} {...props} dispose={null}>
      <group position={[0.35, -0.12, -0.61]} scale={[0.75, 0.75, 0.75]}>
        <mesh
          material={materials['Plastique noir']}
          geometry={nodes.Cylinder008.geometry}
          position={[5.43, 3.95, -0.11]}
          rotation={[Math.PI / 2, 0, 0]}
          scale={[1.19, 1.34, 1.19]}
        />
      </group>
    </group>
  )
}

useGLTF.preload('models/scrollRight.glb')

/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import React, { useRef } from 'react'
import { useGLTF, useNormalTexture } from '@react-three/drei'
import { DoubleSide, Vector2, Vector3 } from 'three'

export default function Model(props) {
  const group = useRef()
  const { nodes, materials } = useGLTF('models/back.glb')
  const [normalMap] = useNormalTexture(
    39,
    {
      offset: [0, 0],
      repeat: [8, 8],
      anisotropy: 4
    }
  )
  const [normalMap2] = useNormalTexture(
    39,
    {
      offset: [0, 0],
      repeat: [8, 8],
      anisotropy: 4
    }
  )
  return (
    <group ref={group} {...props} dispose={null}>
      <mesh
        castShadow
        geometry={nodes.Object033.geometry}
        position={[0.35, 0.18, -2.2]}
        rotation={[Math.PI / 2, 0, 0]}
        scale={[1.06, 1.01, 1.06]}>
        <mesh geometry={nodes.Object031.geometry} position={[0, 0, 0]}>
          <meshStandardMaterial envMapIntensity={0.1}  metalness={0} roughness={1} color={props.color} />
        </mesh>
        <meshStandardMaterial envMapIntensity={0.1} side={DoubleSide} metalness={0} roughness={1} color={props.color} />
      </mesh>
    </group>
  )
}

/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import React, { useRef } from 'react'
import { useGLTF } from '@react-three/drei'

export default function Model(props) {
  const group = useRef()
  const { nodes, materials } = useGLTF('models/mylens.glb')
  return (
    <group ref={group} {...props} dispose={null}>
      <mesh
        material={nodes.Plane001.material}
        geometry={nodes.Plane001.geometry}
        position={[0.35, 3.7, 0.84]}
        rotation={[Math.PI / 2, 0, 0]}
        scale={[3.1, 3, 3]}
      >
        <meshStandardMaterial
          envMapIntensity={0.1}
          color={props.color}
          metalness={0.2}
          roughness={0.3}
        ></meshStandardMaterial>
      </mesh>
    </group>
  );
}

useGLTF.preload('models/mylens.glb')

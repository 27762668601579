/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import React, { useRef } from 'react'
import { useGLTF } from '@react-three/drei'

export default function Model(props) {
  const group = useRef()
  const { nodes, materials } = useGLTF('models/extConnector.glb')
  return (
    <group ref={group} {...props} dispose={null}>
      <mesh
        material={materials['Metal réflechissant']}
        geometry={nodes.Box020.geometry}
        position={[5.10, 5.13, -0.78]}
        rotation={[-Math.PI, 0, Math.PI / 2]}
        scale={[1.1, 1, 1]}
      >
        <meshStandardMaterial envMapIntensity={0.3} color="#999" roughness={1} metalness={1}/>
      </mesh>
      
    </group>
  )
}

useGLTF.preload('models/extConnector.glb')

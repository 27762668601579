import "./App.css";

import * as THREE from "three";
import { Suspense, useState, useRef, useEffect } from "react";
import { Canvas, useFrame } from "react-three-fiber";
import { useQueryState } from "use-location-state";

import Front from "./Front";
import Back from "./Back";
import Buttons from "./Buttons";
import Dpad from "./Dpad";
import StartButtons from "./Pills";
import ExtConnector from "./ExtConnector";
import ScrollRight from "./ScrollRight";
import ScrollLeft from "./ScrollLeft";
import Lens from "./Mylens";
import Cartridge from "./Cartridge";
import OnOff from "./Onoff";
import DcConnector from "./DcConnector";
import Screws from "./Screws";

import MenuColorPicker from "./MenuColorPicker";
import MenuItem from "./MenuItem";

import { RiBrush2Fill } from "react-icons/ri";

import { Loader } from "@react-three/drei";
import {
  useGLTF,
  Plane,
  Sphere,
  Text,
  Environment,
  softShadows,
  Box,
  PerspectiveCamera,
  Html,
} from "@react-three/drei";
import { OrbitControls } from "@react-three/drei/core/OrbitControls";

import { useSpring, animated as a } from "react-spring/three";

function Camera(props) {
  const dummy = new THREE.Vector3();

  console.log(props.controlsRef);
  useFrame((state, delta) => {
    // if (!props.isSwitchedOn) {
    //   const step = 0.05;
    //   state.camera.position.lerp(dummy.set(0, 0, -29), step);
    //   state.camera.lookAt(0, 0, 0);
    //   state.camera.updateProjectionMatrix();
    // }
    // if (props.viewCredits) {
    //   if (props.aboutRef.current.position != null) {
    //     props.controlsRef.current.enabled = false
    //     const step = 0.05
    //     props.controlsRef.current.target = props.aboutRef.current.position
    //     // state.camera.position.lerp(dummy.set(0, 0, -29), step)
    //     props.aboutRef.current.position.lerp(dummy.set(0, 0, -60), step)
    //     state.camera.position.lerp(dummy.set(0, 0, -20), step)
    //     state.camera.lookAt(0, 0, 0)
    //     state.camera.updateProjectionMatrix()
    //   }
    // }
  });

  return (
    <>
      <PerspectiveCamera makeDefault position={[20, 0, 20]} zoom={1} />
    </>
  );
}

function App() {
  // User Customisations
  const editions = [
    {
      name: "DMG-01 Off-white (1989)",
      colors: ["#e8e7e5", "#b00d55", "#2b2b2b", "#8e878d", "#8e878d"], // Order: case, button, dpad, startselect, lens
    },
    {
      name: "DMG-01 Play It Loud Deep Black (1995)",
      colors: ["#111", "#8e878d", "#8e878d", "#8e878d", "#8e878d"],
    },
  ];

  const [activeEdition, setActiveEdition] = useState(0);

  const [color, setColor] = useQueryState(
    "c",
    editions[activeEdition].colors[0]
  );
  const [buttonColor, setbuttonColor] = useQueryState(
    "b",
    editions[activeEdition].colors[1]
  );
  const [dpadColor, setDpadColor] = useQueryState(
    "d",
    editions[activeEdition].colors[2]
  );
  const [startStopColor, setStartStopColor] = useQueryState(
    "s",
    editions[activeEdition].colors[3]
  );
  const [lensColor, setlensColor] = useQueryState(
    "l",
    editions[activeEdition].colors[4]
  );
  const [hasBeenCustomised, setHasBeenCustomised] = useQueryState("hbc", false);
  const [modelName, setModelName] = useQueryState("n", "Anonymous Colorway");

  // App state
  const [isSwitchedOn, setIsSwitchedOn] = useState(true);
  const [cartridgeIsOut, setCartridgeIsOut] = useState(false);
  const [navigationOpen, setNavigationOpen] = useState(null);
  const [mobileNavOpen, setMobileNavOpen] = useState(false);

  const aboutTextRef = useRef(null);
  const controlsRef = useRef(null);

  const windowSize = useWindowSize();

  softShadows();

  const switchMovement = useSpring({
    position: isSwitchedOn ? [0, 0, 0] : [-0.6, 0, 0],
  });

  const cartridgeMovement = useSpring({
    position: cartridgeIsOut ? [0, 3, 0] : [0, 0, 0],
  });

  const buttonAction = function () {
    setIsSwitchedOn(!isSwitchedOn);
    setTimeout(() => {
      setCartridgeIsOut(!cartridgeIsOut);
    }, 500);
  };

  function clickAlert() {
    alert("I am working");
  }

  function useWindowSize() {
    const [windowSize, setWindowSize] = useState({
      width: undefined,
      height: undefined,
    });

    useEffect(() => {
      function handleResize() {
        setWindowSize({
          width: window.innerWidth,
          height: window.innerHeight,
        });
      }

      window.addEventListener("resize", handleResize);

      handleResize();

      return () => window.removeEventListener("resize", handleResize);
    }, []); // Empty array ensures that effect is only run on mount

    return windowSize;
  }

  return (
    <div className="App select-none" style={{ overscrollBehavior: "contain" }}>
      <a
        href="https://alasdairmonk.com"
        className="absolute bottom-2 cursor-pointer shadow-xl text-sm text-gray-600 py-2 right-2 z-50 bg-white bg-opacity-25 rounded-lg px-4"
        style={{
          overscrollBehavior: "contain",
          WebkitBackdropFilter: "blur(16px)",
        }}
      >
        A thing by @almonk
      </a>
      <div
        className={`absolute left-2 bottom-2 text-sm z-50 flex flex-col lg:flex-row items-start`}
        onTouchMove={(e) => {
          e.preventDefault();
        }}
      >
        <div
          className={`rounded-lg bg-white transform transition-all bg-opacity-25 shadow-xl ${
            mobileNavOpen || windowSize.width >= 1024
              ? "opacity-100 pointer-events-auto translate-y-0 translate-x-0 scale-100"
              : "scale-75 translate-y-10 opacity-0 pointer-events-none"
          } lg:pointer-events-auto lg:opacity-100`}
          style={{
            overscrollBehavior: "contain",
            WebkitBackdropFilter: "blur(16px)",
          }}
        >
          <div className="flex flex-col lg:flex-row">
            <MenuColorPicker
              title="Case"
              open={navigationOpen == "case"}
              onSelected={() => {
                setNavigationOpen(navigationOpen == "case" ? null : "case");
              }}
              onChangeColor={(color) => {
                setColor(color.hex);
                setHasBeenCustomised(true);
              }}
              isFirst
              color={color}
              navId="case"
            />
            <MenuColorPicker
              title="Buttons"
              open={navigationOpen == "buttons"}
              onSelected={() => {
                setNavigationOpen(
                  navigationOpen == "buttons" ? null : "buttons"
                );
              }}
              onChangeColor={(color) => {
                setbuttonColor(color.hex);
                setHasBeenCustomised(true);
              }}
              color={buttonColor}
              navId="buttons"
            />
            <MenuColorPicker
              title="Lens"
              open={navigationOpen == "lens"}
              onSelected={() => {
                setNavigationOpen(navigationOpen == "lens" ? null : "lens");
              }}
              onChangeColor={(color) => {
                setlensColor(color.hex);
                setHasBeenCustomised(true);
              }}
              color={lensColor}
              navId="lens"
            />
            <MenuColorPicker
              title="D-pad"
              open={navigationOpen == "dpad"}
              onSelected={() => {
                setNavigationOpen(navigationOpen == "dpad" ? null : "dpad");
              }}
              onChangeColor={(color) => {
                setDpadColor(color.hex);
                setHasBeenCustomised(true);
              }}
              color={dpadColor}
              navId="dpad"
            />
            <MenuColorPicker
              title="Start/Select"
              open={navigationOpen == "startselect"}
              onSelected={() => {
                setNavigationOpen(
                  navigationOpen == "startselect" ? null : "startselect"
                );
              }}
              onChangeColor={(color) => {
                setStartStopColor(color.hex);
                setHasBeenCustomised(true);
              }}
              isLast
              color={startStopColor}
              navId="startselect"
            />
          </div>
        </div>
        <div className="flex">
          <div
            className={`lg:hidden lg:ml-2 mt-2 lg:mt-0 mr-2 flex flex-col h-9 w-9 justify-center items-center text-white cursor-pointer shadow-xl text-lg right-2 z-50 bg-black  rounded-full ${
              mobileNavOpen ? "bg-opacity-40" : "bg-opacity-25"
            }`}
            style={{
              overscrollBehavior: "contain",
              WebkitBackdropFilter: "blur(16px)",
            }}
            onClick={() => {
              setMobileNavOpen(!mobileNavOpen);
              setNavigationOpen(null);
            }}
          >
            <RiBrush2Fill className="text-white" />
          </div>
          {hasBeenCustomised && (
            <div
              className="lg:ml-2 mt-2 lg:mt-0 cursor-pointer shadow-xl text-sm text-gray-600  right-2 z-50 bg-white bg-opacity-25 rounded-lg"
              style={{
                overscrollBehavior: "contain",
                WebkitBackdropFilter: "blur(16px)",
              }}
            >
              <MenuItem
                title="Share"
                open={navigationOpen == "share"}
                onSelected={() => {
                  setNavigationOpen(navigationOpen == "share" ? null : "share");
                  setMobileNavOpen(false);
                }}
                isFirst
                isLast
              >
                <div className="flex flex-col">
                  {hasBeenCustomised && (
                    <div className="border-b pb-2 mb-2">
                      <p className="text-gray-600 mb-1">
                        Name your unique design
                      </p>
                      <input
                        placeholder="Something creative&hellip;"
                        className="rounded px-2 py-1 bg-gray-200"
                        value={modelName}
                        onChange={(e) => {
                          setModelName(e.target.value);
                        }}
                      ></input>
                    </div>
                  )}
                  <div className="text-gray-600 mb-1">Shareable URL</div>
                  <input
                    placeholder="Something creative&hellip;"
                    className="rounded py-1 bg-white text-blue-600 underline"
                    onClick={(e) => {
                      e.target.select();
                    }}
                    readOnly
                    value={window.location.href}
                  ></input>
                </div>
              </MenuItem>
            </div>
          )}
        </div>
      </div>
      <Canvas
        concurrent
        shadowMap
        pixelRatio={2}
        style={{
          background: "#9c9c9f",
          width: "100vw",
          height: "100vh",
        }}
      >
        <Suspense fallback={null}>
          <fog attach="fog" args={["#9c9c9f", 40, 100]} />
          <PerspectiveCamera makeDefault position={[20, 0, 20]} zoom={1} />
          <ambientLight intensity={0.25} />
          <directionalLight castShadow position={[1, 30, 10]} intensity={0.6} />
          <pointLight position={[1, 30, -10]} intensity={0.3}></pointLight>
          <Environment background={false} preset={"studio"} />
          <group position={[-0.3, 0, 0]}>
            <Back color={color} />
            <Front color={color} />
            <Dpad color={dpadColor} />
            <Buttons color={buttonColor} />
            <a.group {...switchMovement}>
              <OnOff
                position={[-1.1, 3.81, -0.5]}
                color={dpadColor}
                onClick={buttonAction}
              />
            </a.group>
            <ScrollLeft />
            <StartButtons color={startStopColor} />
            <ExtConnector />
            <DcConnector />
            <Screws />
            <ScrollRight />
            <Lens color={lensColor} />
            <a.group {...cartridgeMovement}>
              <Cartridge />
            </a.group>
            {/* LED */}
            <mesh position={[-3.16, 4.35, 0.83]}>
              <Sphere scale={[0.15, 0.15, 0.15]}>
                <meshPhongMaterial
                  color={isSwitchedOn ? "red" : "black"}
                  roughness={1}
                  emissive={isSwitchedOn ? "red" : "black"}
                  emissiveIntensity={1}
                />
              </Sphere>
            </mesh>

            {/* Screen */}
            <mesh position={[0, 4, 0.9]}>
              <Plane args={[6, 6]}>
                <meshPhongMaterial
                  color={"#444"}
                  envMapIntensity={1}
                  metalness={0}
                  roughness={0.8}
                />
              </Plane>
            </mesh>
          </group>

          {/* Ground */}
          <mesh
            rotation-x={Math.PI * -0.5}
            receiveShadow
            position={[0, -7.7, 0]}
          >
            <Plane receiveShadow args={[400, 400]}>
              <meshStandardMaterial
                envMapIntensity={0.1}
                color="#a3a3a3"
                metalness={1}
                roughness={0.8}
              />
            </Plane>
          </mesh>
          <Box></Box>
          <OrbitControls
            ref={controlsRef}
            enableZoom={true}
            enablePan={true}
            maxPolarAngle={1.5}
          />
          <Text
            color={"#fff"}
            ref={aboutTextRef}
            fontSize={0.4}
            maxWidth={200}
            lineHeight={1}
            letterSpacing={0.02}
            textAlign={"left"}
            anchorX="center"
            position={[0, 9, 0]}
            anchorY="middle"
          >
            {hasBeenCustomised === false
              ? editions[activeEdition].name
              : `DMG-01 ${modelName} (${new Date().getFullYear()})`}
          </Text>
        </Suspense>
      </Canvas>
    </div>
  );
}

useGLTF.preload("models/front.glb");
useGLTF.preload("models/back.glb");

export default App;

import { CompactPicker } from "react-color";

export default function MenuColorPicker(props) {
  return (
    <div
      className={`flex items-center flex-auto ${
        props.isFirst
          ? "rounded-tl-lg rounded-tr-lg lg:rounded-tr-none lg:rounded-tl-lg lg:rounded-bl-lg"
          : ""
      } ${
        props.isLast
          ? "rounded-br-lg rounded-bl-lg lg:rounded-bl-none lg:rounded-tr-lg lg:rounded-br-lg"
          : "lg:border-r"
      } border-black border-opacity-10 bg-opacity-50 relative cursor-pointer ${
        props.open ? "bg-gray-100" : ""
      }`}
      onClick={props.onSelected}
      style={{ borderTopColor: props.color, borderLeftColor: props.color }}
    >
      <div className="flex flex-row items-center px-4 py-2">
        <div
          className="h-4 w-4 rounded-full mr-2 shadow"
          style={{ backgroundColor: props.color }}
        ></div>
        <div className="text-gray-600 truncate">{props.title}</div>
      </div>
      <div
        className={`absolute left-0 bottom-0 mb-10 transition-all shadow-xl overflow-hidden rounded transform ${
          props.open
            ? "opacity-100 pointer-events-auto translate-y-0 translate-x-0 scale-100"
            : "scale-75 translate-y-10 opacity-0 pointer-events-none"
        }`}
        onClick={(e) => {
          e.stopPropagation();
          e.nativeEvent.stopImmediatePropagation();
        }}
      >
        <CompactPicker
          triangle="hide"
          disableAlpha
          onChange={props.onChangeColor}
          color={props.color}
        />
      </div>
    </div>
  );
}

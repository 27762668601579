/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import React, { useRef } from 'react'
import { useGLTF } from '@react-three/drei'

export default function Model(props) {
  const group = useRef()
  const { nodes, materials } = useGLTF('models/onoff.glb')
  return (
    <group ref={group} {...props} dispose={null}>
      <mesh
        material={materials['Plastique grise']}
        geometry={nodes.Box019.geometry}
        position={props.position}
        rotation={[Math.PI / 2, 0, 0]}
        className="cursor-pointer" 
        scale={[0.97, 0.97, 0.97]}
      >
        <meshStandardMaterial roughness={0.6} color={props.color} />
        </mesh>
    </group>
  )
}

useGLTF.preload('models/onoff.glb')

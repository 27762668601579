import {RiShareBoxLine} from "react-icons/ri"
export default function MenuItem(props) {
  return (
    <div
      className={`flex items-center flex-auto ${
        props.isFirst ? "rounded-tl-lg rounded-bl-lg" : ""
      } ${
        props.isLast ? "rounded-tr-lg rounded-br-lg" : "lg:border-r"
      } border-black border-opacity-10 bg-opacity-50 relative cursor-pointer ${
        props.open ? "bg-gray-100" : ""
      }`}
      onClick={props.onSelected}
      style={{ borderTopColor: props.color, borderLeftColor: props.color }}
    >
      <div className="flex flex-row items-center px-4 py-2">
        <div className="text-base">
          <RiShareBoxLine/>
        </div>
        <div className="text-gray-600 truncate ml-2">{props.title}</div>
      </div>
      <div
        className={`absolute left-0 cursor-default bottom-0 mb-10 transition-all shadow-xl bg-white p-4 overflow-hidden rounded transform ${
          props.open
            ? "opacity-100 pointer-events-auto translate-y-0 translate-x-0 scale-100"
            : "scale-75 translate-y-10 opacity-0 pointer-events-none"
        }`}
        onClick={(e) => {
          e.stopPropagation();
          e.nativeEvent.stopImmediatePropagation();
        }}
      >
        {props.children}
      </div>
    </div>
  );
}

/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import React, { useRef } from 'react'
import { useGLTF } from '@react-three/drei'

export default function Model(props) {
  const group = useRef()
  const { nodes, materials } = useGLTF('models/scrollLeft.glb')
  return (
    <group ref={group} {...props} dispose={null}>
      <group position={[-0.55, 0.5, -0.54]} scale={[0.6, 0.6, 0.6]}>
        <mesh
          material={materials['Plastique noir']}
          geometry={nodes.Cylinder007.geometry}
          position={[-5.5, 4.5, 0.43]}
          rotation={[Math.PI / 2, 0, 0]}
          scale={[1.41, 1.07, 1.41]}
        />
      </group>
    </group>
  )
}

useGLTF.preload('models/scrollLeft.glb')

/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import React, { useRef } from 'react'
import { useGLTF } from '@react-three/drei'

export default function Model(props) {
  const group = useRef()
  const { nodes, materials } = useGLTF('models/dpad.glb')
  return (
    <group ref={group} {...props} dispose={null}>
      <mesh
        material={materials["Plastique noir"]}
        geometry={nodes.Cylinder003.geometry}
        position={[-2.47, -2.61, 0.6]}
        rotation={[Math.PI / 2, 0, 0]}
        scale={[1.06, 1.06, 1.06]}
      >
        <meshStandardMaterial
          envMapIntensity={0.2}
          roughness={0.7}
          color={props.color}
        />
      </mesh>
    </group>
  );
}

useGLTF.preload('models/dpad.glb')
